.header {
    background-color: #212121;
    color: #f4cb6f;
    font-family: 'Roboto', sans-serif;
    z-index: 10;
    
    /* Estilos adicionais */
  }
  /* Estilos do cabeçalho */
.logo{
 
      height:70px;
    
    
}
.box {
  position: absolute;
    top: 0;
    width: 200px;
    height: 70px;
    background: #212121;
    left: 40px;
}

.header h1 {
  margin: 0;
}
.typography{
  line-height: 0!important;
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px 0 0;
}
.user-info .user-letter {
  width: 56px;
  display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  background: 'transparent';
  color: #fff;
  border-radius: 25px;
  align-items: center;
  
  margin-right: 10px;
  /* border: 1px solid #f4cb6f; */
}
.user-info .user-name {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
}
.user-info .user-role {
  display: block;
  color: #31D841;
  font-size: 12px;
  text-align: left;
}