/* Reset de estilos */
html, body, #root {
  margin: 0;
  padding: 0;
}
h2 {
  display: block;
  font-size: 1.2em;
  margin-block-start: 0.83em!important;
  margin-block-end: 0.53em!important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  margin-bottom:0 !important;
}
div{
  font-family: "Montserrat","Roboto", "Helvetica", "Arial", sans-serif; 
  font-weight: 500;
  line-height: 1.4em;
}
.root{
  width: 100%;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'flex-start';
  height: '100%';
  text-align: 'center';
}

.circulo{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  margin: 10px auto;
  background-color: transparent;
  border: 1px solid #fff;
}

.button-referral{
  background-color: transparent!important;
    border: 1px solid white!important;
    border-radius: 15px!important;
    width: 240px;
    /* padding: 10px 20px; */
    display: flex!important;
    justify-content: space-between!important;
    
}

form{
  min-width: 320px;
  max-width: 400px;
  margin-top: 15px !important;
  /*  background-color: #6f2dbd50!important;*/
}

/* Style the scrollbar for the entire document */
::-webkit-scrollbar {
  width: 10px;
  background-color: #6f2dbd50;
}

/* Style the thumb of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #31D841;
  border-radius: 5px;
}

/* Style the track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #6f2dbd;
  border-radius: 5px;
}

/* Estilo global da aplicação */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color:rgb(255, 255, 255),
}

.css-rtrcn9-MuiTablePagination-root {
  overflow: auto;
  color: #31D841!important;
  font-size: 0.875rem;
}

.css-cveggr-MuiListItemIcon-root {
  color: #31D841!important;
}

.leftContent{
  overflow: hidden;
  display: flex;
  width : calc(100vw - 400px);
  justify-content: center;
/* background: no-repeat fixed center url( "./assets/images/bg.png"); */
  height: 100vh;
  
}
.rightContent{
  flex:1;
  height: 100vh;
  background: white;
  padding: 7px 0px;
  width:400px;
  overflow-y: auto;

}

.logoContainer{
  width:100%;
  text-align:center;
}

.imgBackgroundContainer{
  text-align:center;
  width:100%;
  max-height:-webkit-fill-available;
}
.imgBackground{
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: none;
}
.imgTexto{
  position: relative;
  bottom: 613px;
  width: 80%;
  max-height: 100%;
  max-width: 600px;
  
}
.imgGlobo{
  margin-top: 50px;
  position: relative;
  bottom: 613px;
  width: 80%!important;
  max-width: 280px!important;
  padding: 22px;
  
}

.MuiCardContent-root {
  padding: 2px!important;
   min-height: 30px!important; 
}

.notification-container {
  border-radius: 15px!important;
}
.content {
  /* display: flex; */
  flex: 1;
  align-content: center !important;
  
  text-align: center;
  width: 90% !important;
  max-width: 600px !important;
  min-width: 350px!important;
  align-items: center!important;
  flex-direction: column!important;
  justify-content: flex-start!important;

  
}
.main-content {
  display: flex;
  flex: 1;
  min-height: 100vh;
  /* justify-content: center; */
  /* background: no-repeat fixed center url( "./assets/images/bg.png"); */
  
}

.makeStyles-container-26 {
  min-height: 70vh;
  height: 70vh!important;
}

.css-nzinn5-MuiContainer-root {
  z-index: 2!important;
}


/* Estilos da barra lateral */
.sidebar {
  background-color: #31D841;
  color: #fff;
  width: 200px;
  padding: 20px;
}

.chatbox{
  width: 90%;
  max-width: 800px;

}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
}

/* Estilos do rodapé */
.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
}
.button{
  /* background: rgb(104,18,200), */
  background: linear-gradient(135deg, rgba(104,18,200,1) 0%, rgba(12,170,143,1) 50%, rgba(26,32,159,1) 100%);
  color: #E8CA74;
}
.backbutton{
  color: #31D841!important;
}
.MuiTypography-h4{
  position: relative!important;
}
@media (min-width: 900px) {
  .css-1t6c9ts {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-evenly!important;
  }
}
.content{
  padding: 0px 20px;
  background-color:#ffffff30;
}
.MuiButton-root  {
  font-family: Montserrat!important;
  color: #6f2dbd!important;
  line-height: 1.55;
  font-size: 0.740rem!important;
 }

.MuiButton-contained{
  font-weight: bold!important;
  color: white!important;
  background: linear-gradient(90deg, rgba(104,18,200,1) 0%, rgba(12,170,143,1) 50%, rgba(26,32,159,1) 100%);
}
h1{
  z-index: 2;
  color:#31D841;
}
h4{
  color:#31D841;
}
h3{
  color:#31D841;
}
.css-binzgt {
  margin-top: 25px;
}
.MuiMenu-paper {
  background-color: #212121 !important;
  color: #31D841!important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper , .css-1ps6pg7-MuiPaper-root {
  background-color: #212121 !important;
  color: #31D841!important;
}
.MuiFormLabel-root{
  color:#2F2F33!important;
  font-family: 'Montserrat' !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #31D841!important;
}
.MuiInputBase-root {
  font-family: 'Montserrat'!important;
  color:#2F2F33!important;
}
.text{
  padding: 5px;
  color:#31D841!important;
}
.MuiIconButton-colorPrimary {
    color: #31D841!important;
}
.title{
  font-size: 1.4rem !important;
    color: #2F2F33;
    font-weight: bold!important;
    margin: 10px !important;
}
.subtitle {
  font-size: 0.7rem;
}
.iconEdit{
  color: #31D841 !important;
}
.iconDetail{
  color:#31D841!important;
  font-size: 14;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.2rem!important;
  user-select: none;
  color: #31D841 !important;
}
.svgBlack {
 
  color: black !important;
}
.iconDelete {
  color:'#C3043E';
}
.MuiInputBase-input {
  /* height: 2em!important; */
  padding: 7px 0 7px;
  font-size: .8rem !important;
  color:#2F2F33!important;
  font-family: 'Montserrat'!important;
}
.makeStyles-content-35 ,
.makeStyles-content-33,  
.makeStyles-content-30 {
  text-align: center;
  width: 90%!important;
  /* max-width: 600px !important; */
  min-width: 350px!important;
}
/* .MuiInputLabel-outlined {
  color: #31D841 !important;
} roxo:#6f2dbd*/
.css-mjzrhn-MuiListSubheader-root{
  background-color:#6f2dbd !important;
  color: #31D841!important;
}
.MuiTooltip-tooltip {
  color: #6f2dbd!important;
  background-color: #31D841!important;
  padding: 4px!important;
  font-size: 0.675rem!important;
  max-width: 400px;
  word-wrap: break-word;
  font-family: "Montserrat","Roboto", "Helvetica", "Arial", sans-serif; 
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  margin:2px !important;
}


.css-binzgt {
  
  height: fit-content;
  margin: 0 20px!important;
}

.makeStyles-content-36 {
  text-align: center;
  width: 600px;
  max-width: 80%;
}

.listContainer{
   
  max-height: calc(100vh - 200px);
  width: 800px!important;
  max-width:80vw;
  overflow: auto;

  
}

.bgcontent{
  left: 0;
    top: 71px;
    position: absolute;
    width: 100%;
    height:calc(100% + 71px) !important;
    z-index: 0;
    
    img{
      min-height: 100%;
      max-height: 100%;
    }
}

.css-q8azlf {
  
  position: relative!important;
  bottom: 0px!important;
  width: 100%!important;
  background-color: #eeeeee;
}

.listContainer::-webkit-scrollbar {
  width: 10px;
}
.listContainer::-webkit-scrollbar-track {
  background-color: #e8ca74;
}
.listContainer::-webkit-scrollbar-thumb {
  background-color: #31D841!important;
}
.listContainer::-webkit-scrollbar-thumb:hover {
  background-color: #31D841!important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
    color: #000!important;
}
.css-pxy73m-MuiDataGrid-root {
  color:#6812C8!important;
  font-family: 'Montserrat' !important;
}

/* roxo #6f2dbd */
/* tabpanel */
.css-19kzrtu {
  margin: 0 auto!important;
}
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
 
  color: #31D841!important;
}

.MuiButton-text {
  padding: 6px 8px;
  
}
.Mui-Focused {
  color: #000!important;
}
.MuiButtonBase-root-MuiButton-root {
  padding-left: 16px;
  z-index: 2;
  color: #31D841!important;
}
.MuiButtonBase-root .MuiFormLabel-root {
  font-family: 'Montserrat'!important;
}
.MuiTypography-root {
  font-family: 'Montserrat'!important;
}

.css-uym98a-MuiImageList-root {
  margin: 0 auto;
}
.user-info-column {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px 0 0;
  flex-direction: column;
}

.css-1xwqcgb-MuiTypography-root {
  margin: 0;
  font-weight: 600!important;
  font-size: 0.975rem!important;
  line-height: 1.43;
  display: block;
}

.css-p0xady {
  width: 100%;
  height: fit-content!important;
}

.MuiCard-root {
  /* background-color: #212121 !important; */
  margin: 5px!important;
  color: #65656B!important;
  border-radius: 15px!important;
}

.MuiButton-outlined{
  color: #6f2dbd!important;
}


.MuiCardActions-root {
  background: linear-gradient(90deg, rgba(104,18,200,1) 0%, rgba(12,170,143,1) 50%, rgba(26,32,159,1) 100%);
  border-radius: 15px;
  color: white;
}
.MuiCardHeader-root {
  background-color: #2b2b30 !important;
  /* margin: 5px 0px!important;  */
  color: white!important;
 
}

.css-1soat4t-MuiTypography-root {
  
  color: white!important;
  
}

.css-s6tt8y {
  color: white!important;
}

.button-container { 
  display: flex; gap: 10px; 
  
} 
.selected{
  background-color: #6f2dbd!important;
}
.MuiTypography-body1 {
  font-size: 0.875rem!important;
}
button { padding: 10px 20px; font-size: 16px; cursor: pointer; } 
  button.selected {background: linear-gradient(90deg, rgba(104,18,200,1) 0%, rgba(12,170,143,1) 50%, rgba(26,32,159,1) 100%);
    border-radius: 15px;
    color: white!important;}

.MuiTypography-colorPrimary {
  color: #65656B!important;
}

.file-upload-img {
  display: flex;
    text-align: center;
    border: 3px dashed rgb(210, 227, 244);
    margin: .5rem;
    padding: .2rem;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    align-content: center;
    align-items: center;
    img{
      max-height:120px;
    }
}
.file-upload {
  max-height: 300px;
  text-align: center;
  /* border: 3px dashed rgb(210, 227, 244); */
  margin: .5rem;
  padding: .2rem;
  position: relative;
  cursor: pointer;
  min-width: 260px;
}
.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.MuiFormControl-root {
  padding: 5px;
  width: 90%!important;
  font-family: 'Montserrat'!important;
}
.base-Popper-root {
  
  font-family: 'Montserrat'!important;
}
input:-internal-autofill-selected {
  background: transparent!important;
}
.roxo{
  color: #6f2dbd!important;
}
.verde{
  color: #31D841!important;
}

